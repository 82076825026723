<script setup lang="ts">
import type { FiltersResponseSchema } from '@laam/cms-shared';
import { FormField, ToggleButton, Button, Input } from '@laam/ui/base';
import { PhMagnifyingGlass } from '@phosphor-icons/vue';
import { computed, inject, ref, type Ref } from 'vue';

const emit = defineEmits<{
	(
		e: 'update-filter',
		value: {
			filterType: string;
			filterValue: string;
			source: string;
		},
	): void;
}>();

const filters = inject('filters') as Ref<FiltersResponseSchema>;
const PAGE_LIMIT = 10;

const currentPage = ref(1);

const filteredBrandsList = computed(() => {
	if (!searchVal.value) {
		return filters.value.brands!;
	}
	return filters.value?.brands!.filter((brand) =>
		brand.includes(searchVal.value.toLowerCase()),
	);
});

const totalPages = computed(() =>
	Math.ceil(filteredBrandsList.value.length / PAGE_LIMIT),
);
const searchVal = ref('');

const slicedBrandsList = computed(() => {
	return filteredBrandsList.value.slice(0, currentPage.value * PAGE_LIMIT);
});

const handlePageChange = () => {
	currentPage.value++;
};
const logEvent = (brand: string) => {
	emit('update-filter', {
		filterType: 'brand',
		filterValue: brand,
		source: window.location.href,
	});
};
</script>
<template>
	<div class="flex flex-col brand_filter_form gap-xl">
		<Input
			v-model:model-value="searchVal"
			:auto-focus="false"
			placeholder="Search"
		>
			<template #leftIcon>
				<PhMagnifyingGlass size="16" />
			</template>
		</Input>

		<div class="flex flex-wrap gap-xl">
			<FormField
				v-for="brand in slicedBrandsList"
				v-slot="{ handleChange, value }"
				:key="brand"
				type="checkbox"
				name="brand"
				:value="`${brand}`"
				:unchecked-value="false"
			>
				<ToggleButton
					:checked="value.includes(brand)"
					size="sm"
					class="font-medium capitalize"
					@update:checked="
						(e) => {
							handleChange(e);
							logEvent(brand);
						}
					"
				>
					{{ brand }}
				</ToggleButton>
			</FormField>
			<Button
				v-if="currentPage < totalPages"
				class="font-medium"
				variant="secondary"
				@click="handlePageChange"
			>
				...
			</Button>
		</div>
	</div>
</template>
