<script setup lang="ts">
import { type HTMLAttributes, computed } from 'vue';
import {
	DropdownMenuSeparator,
	type DropdownMenuSeparatorProps,
} from 'radix-vue';
import { cn } from '@laam/lib/utils';

const props = defineProps<
	DropdownMenuSeparatorProps & {
		class?: HTMLAttributes['class'];
	}
>();

const delegatedProps = computed(() => {
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const { class: _, ...delegated } = props;

	return delegated;
});
</script>

<template>
	<DropdownMenuSeparator
		v-bind="delegatedProps"
		class="dropdown-menu--separator"
		:class="cn(' my-xxs h-[1px] bg-gray-200 ', props.class)"
	/>
</template>
