<script setup lang="ts">
import type { FiltersResponseSchema } from '@laam/cms-shared';
import { ExpressBadge, Toggle } from '@laam/ui/base';
import { useField } from 'vee-validate';

const filters = inject('filters') as globalThis.Ref<FiltersResponseSchema>;
const { $eventClient } = useNuxtApp();
const emits = defineEmits<{
	(e: 'update:expressToggle'): void;
}>();

const show = computed(
	() => !!filters.value?.delivery_days?.find((t) => t.includes('readytoship')),
);

const field = useField<string[]>('delivery_days');
const handleCheckboxChange = (value: boolean) => {
	if (value) {
		const uniqueDeliveryFilters = new Set(field?.value.value);
		uniqueDeliveryFilters.add('readytoship');
		field?.setValue([...uniqueDeliveryFilters]);
		$eventClient.sendEvent('filter-applied', {
			filterType: 'delivery_days',
			filterValue: 'readytoship',
			source: window.location.href,
		});
	} else {
		const uniqueDeliveryFilters = new Set(field?.value.value);
		uniqueDeliveryFilters.delete('readytoship');
		field?.setValue([...uniqueDeliveryFilters]);
	}
	emits('update:expressToggle');
};
</script>
<template>
	<div
		v-if="show"
		class="flex items-center border express_toggle px-lg py-md gap-md lg:px-xl lg:gap-xs rounded-medium w-fit"
	>
		<ExpressBadge />
		<Toggle
			class="express_toggle_button"
			:checked="!!field?.value.value?.includes('readytoship')"
			@update:checked="handleCheckboxChange"
		/>
	</div>
</template>
