import {
	type FilterSchema,
	type FiltersResponseSchema,
} from '@laam/cms-shared';

export const activeFilterStyle =
	/* css */ 'border-gray-800 bg-gray-50 color-gray-800 [&_svg]:fill-gray-800';

export const removeEmptyFilters = (
	filters: FilterSchema,
	filterList: FiltersResponseSchema,
) => {
	const newFilters = { ...filters };
	for (const key in newFilters) {
		const val = newFilters[key as keyof FilterSchema];
		if (val) {
			if (Array.isArray(val) && val.length === 0) {
				delete newFilters[key as keyof FilterSchema];
			}
			if (
				key === 'price' &&
				(val as FilterSchema['price'])!.from === filterList.prices!.min_price &&
				(val as FilterSchema['price'])!.to === filterList.prices!.max_price
			) {
				delete newFilters.price;
			}
		}
	}
	return newFilters;
};

export const SortValueToLabelMap = {
	RECOMMENDED: 'Recommended',
	BEST_SELLING: 'Best Selling',
	DATE_NEW_TO_OLD: 'Newest First',
	PRICE_LOW_TO_HIGH: 'Price: Low to High',
	PRICE_HIGH_TO_LOW: 'Price: High to Low',
};
