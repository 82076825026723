<script setup lang="ts">
import {
	Drawer,
	DrawerContent,
	DrawerFooter,
	DrawerHeader,
	DrawerTitle,
	DrawerTrigger,
	Button,
	Dialog,
	DialogContent,
	DialogDescription,
	DialogHeader,
	DialogTitle,
	DialogTrigger,
	Separator,
	DialogFooter,
	IconButton,
} from '@laam/ui/base';
import AllFilterForm from './forms/AllFilterForm.vue';
import { PhFunnel, PhSlidersHorizontal } from '@phosphor-icons/vue';
import { useFormValues } from 'vee-validate';
import { isMobileView } from '~/utils/helpers';
import type { FilterSchema, FiltersResponseSchema } from '@laam/cms-shared';
const open = ref(false);

const appliedFilters = useFormValues<FilterSchema>();
const drawerToggle = ref(false);
const toggleDropdown = () => {
	drawerToggle.value = !drawerToggle.value;
};
const filters = inject('filters') as globalThis.Ref<FiltersResponseSchema>;

const emits = defineEmits<{
	(e: 'reset-form'): void;
}>();

const filtersCount = computed(() => {
	const keys = Object.keys(appliedFilters.value || {}).filter(
		(key) => key !== 'search',
	);
	let count = 0;
	for (const key of keys) {
		const val = appliedFilters.value[key as keyof FilterSchema];
		if (key == 'sort') {
			continue;
		} else if (key == 'price' && val) {
			const price = val as FilterSchema['price'];
			if (
				price!.from !== filters.value?.prices?.min_price.toString() ||
				price!.to !== filters.value?.prices?.max_price.toString()
			)
				count++;
		} else {
			if (val && Array.isArray(val)) count += val.length;
		}
	}
	return count;
});
const plpNewUi = inject('plpNewUi') as Ref<boolean>;
</script>
<template class="size_filter_sheet">
	<Drawer v-if="isMobileView()" v-model:open="open">
		<DrawerTrigger>
			<IconButton
				v-if="plpNewUi"
				:size="'xs'"
				variant="secondary"
				rounded="full"
			>
				<PhSlidersHorizontal />
			</IconButton>
			<Button v-else :size="'xs'" :variant="'tertiary'">
				<PhFunnel :weight="'bold'" />
				{{ 'Filter ' + (filtersCount ? `(${filtersCount})` : '') }}
			</Button>
		</DrawerTrigger>
		<DrawerContent
			side="bottom"
			class="size_filter_sheet_content"
			:on-open-auto-focus="(e) => e.preventDefault()"
			:on-close-auto-focus="(e) => e.preventDefault()"
		>
			<DrawerHeader>
				<DrawerTitle>All Filters</DrawerTitle>
			</DrawerHeader>
			<div class="grow">
				<AllFilterForm />
			</div>
			<DrawerFooter
				class="sticky w-full bg-white left-none right-none bottom-[-17px]"
			>
				<DrawerTrigger :as-child="true">
					<Button
						size="lg"
						variant="primary"
						class="grow"
						type="submit"
						form="filterForm"
					>
						Apply
					</Button>
				</DrawerTrigger>

				<DrawerTrigger :as-child="true">
					<Button
						size="lg"
						variant="secondary"
						@click="
							() => {
								emits('reset-form');
							}
						"
					>
						Reset
					</Button>
				</DrawerTrigger>
			</DrawerFooter>
		</DrawerContent>
	</Drawer>

	<Dialog v-else v-model:open="drawerToggle">
		<DialogTrigger as-child>
			<IconButton
				v-if="plpNewUi"
				rounded="full"
				variant="secondary"
				size="nav-button"
			>
				<PhSlidersHorizontal />
			</IconButton>
			<Button v-else :size="'md'" :variant="'secondary'">
				<PhFunnel :weight="'bold'" />
				<span class="px-xxs">
					{{ 'Filter ' + (filtersCount ? `(${filtersCount})` : '') }}
				</span>
			</Button>
		</DialogTrigger>
		<DialogContent class="px-0 py-0">
			<DialogHeader class="">
				<DialogTitle class="text-center p-3xl">All Filters</DialogTitle>

				<Separator class="w-full h-[1px]" />

				<DialogDescription class="h-[70vh] overflow-auto p-3xl">
					<AllFilterForm />
				</DialogDescription>
			</DialogHeader>
			<DialogFooter class="flex justify-center p-3xl">
				<Button
					size="lg"
					variant="primary"
					class="grow bg-gray-800 w-full"
					type="submit"
					form="filterForm"
					@click="toggleDropdown"
				>
					{{ 'Apply ' + (filtersCount ? `(${filtersCount})` : '') }}
				</Button>

				<Button
					size="lg"
					:class="'w-full'"
					variant="secondary"
					@click="
						() => {
							emits('reset-form');
							toggleDropdown();
						}
					"
				>
					Reset
				</Button>
			</DialogFooter>
		</DialogContent>
	</Dialog>
</template>
