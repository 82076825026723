<script setup lang="ts">
import { type HTMLAttributes, computed } from 'vue';
import {
	DropdownMenuLabel,
	type DropdownMenuLabelProps,
	useForwardProps,
} from 'radix-vue';
import { cn } from '@laam/lib/utils';

const props = defineProps<
	DropdownMenuLabelProps & { class?: HTMLAttributes['class']; inset?: boolean }
>();

const delegatedProps = computed(() => {
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const { class: _, ...delegated } = props;

	return delegated;
});

const forwardedProps = useForwardProps(delegatedProps);
</script>

<template>
	<DropdownMenuLabel
		v-bind="forwardedProps"
		class="dropdown-menu--label"
		:class="
			cn('px-md py-sm text-sm font-semibold', inset && 'pl-8xl', props.class)
		"
	>
		<slot />
	</DropdownMenuLabel>
</template>
