<script setup lang="ts">
import type { FiltersResponseSchema } from '@laam/cms-shared';
import { FormField, ToggleButton } from '@laam/ui/base';
import { PhMoonStars } from '@phosphor-icons/vue';

const filters = inject('filters') as globalThis.Ref<FiltersResponseSchema>;

const emits = defineEmits<{
	(e: 'update:eidDeliveryToggle'): void;
}>();

// FIXME: This is a temporary solution to show the Eid Delivery toggle
const show = computed(
	() =>
		!!filters.value?.delivery_days?.find((d) =>
			d.includes('deliver_eid delivery_rdlrg'),
		),
);
</script>
<template>
	<div v-if="show">
		<FormField
			v-slot="{ handleChange, value }"
			type="checkbox"
			name="delivery_days"
			value="deliver_eid delivery_rdlrg"
			:unchecked-value="false"
		>
			<ToggleButton
				:size="isMobileView() ? 'xs' : 'md'"
				:checked="!!value?.includes('deliver_eid delivery_rdlrg')"
				variant="outline"
				class="eid_delivery_toggle"
				@update:checked="
					(e) => {
						handleChange(e);
						emits('update:eidDeliveryToggle');
					}
				"
			>
				<PhMoonStars :weight="'fill'" />
				<span class="lg:px-xxs">Eid Delivery</span>
			</ToggleButton>
		</FormField>
	</div>
</template>
