<script setup lang="ts">
import {
	FormField,
	ToggleButton,
	SingleSelectList,
	SingleSelectListItem,
} from '@laam/ui/base';
const emit = defineEmits<{
	(
		e: 'update-filter',
		value: {
			filterType: string;
			filterValue: string;
			source: string;
		},
	): void;
}>();
const logEvent = (name: string) => {
	emit('update-filter', {
		filterType: 'sort_by',
		filterValue: name,
		source: window.location.href,
	});
};
const sortFilters = ref([
	{
		name: 'Recommended',
		value: 'RECOMMENDED',
	},
	{
		name: 'Best Selling',
		value: 'BEST_SELLING',
	},
	{
		name: 'Newest First',
		value: 'DATE_NEW_TO_OLD',
	},
	{
		name: 'Price: Low to High',
		value: 'PRICE_LOW_TO_HIGH',
	},
	{
		name: 'Price: High to Low',
		value: 'PRICE_HIGH_TO_LOW',
	},
]);
const plpNewUi = inject('plpNewUi') as Ref<boolean>;
</script>

<template>
	<div v-if="plpNewUi" class="gap-xl flex flex-wrap">
		<FormField
			v-for="(item, index) in sortFilters"
			v-slot="{ handleChange, value }"
			:key="index"
			class="sort_filter_form"
			name="sort_by"
			type="radio"
		>
			<ToggleButton
				size="sm"
				class="font-medium capitalize"
				:checked="value?.includes(item.value)"
				@update:checked="
					() => {
						handleChange(item.value);
						logEvent(item.value);
					}
				"
			>
				{{ item.name }}
			</ToggleButton>
		</FormField>
	</div>
	<FormField
		v-else
		v-slot="{ componentField }"
		class="sort_filter_form"
		type="radio"
		name="sort_by"
	>
		<SingleSelectList default-value="recommended" v-bind="componentField">
			<SingleSelectListItem
				value="RECOMMENDED"
				class="lg:text-left"
				@click="logEvent('RECOMMENDED')"
			>
				Recommended
			</SingleSelectListItem>
			<SingleSelectListItem
				value="BEST_SELLING"
				class="lg:text-left"
				@click="logEvent('BEST_SELLING')"
			>
				Best Selling
			</SingleSelectListItem>
			<SingleSelectListItem
				value="DATE_NEW_TO_OLD"
				class="lg:text-left"
				@click="logEvent('DATE_NEW_TO_OLD')"
			>
				Newest First
			</SingleSelectListItem>
			<SingleSelectListItem
				value="PRICE_LOW_TO_HIGH"
				class="lg:text-left"
				@click="logEvent('PRICE_LOW_TO_HIGH')"
			>
				Price: Low to High
			</SingleSelectListItem>
			<SingleSelectListItem
				value="PRICE_HIGH_TO_LOW"
				class="lg:text-left"
				@click="logEvent('PRICE_HIGH_TO_LOW')"
			>
				Price: High to Low
			</SingleSelectListItem>
		</SingleSelectList>
	</FormField>
</template>
