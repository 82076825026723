<script setup lang="ts">
import {
	Drawer,
	DrawerContent,
	DrawerFooter,
	DrawerHeader,
	DrawerTitle,
	DrawerTrigger,
	Button,
	DropdownMenu,
	DropdownMenuContent,
	DropdownMenuLabel,
	DropdownMenuSeparator,
	DropdownMenuTrigger,
	DrawerDescription,
} from '@laam/ui/base';
import { PhCaretDown } from '@phosphor-icons/vue';
import { activeFilterStyle } from './utils';
import { useField, useFormValues } from 'vee-validate';
import type { FilterSchema, FiltersResponseSchema } from '@laam/cms-shared';
import { AppliedFilters, PriceFilterForm } from '@laam/ui';

const filters = inject('filters') as globalThis.Ref<FiltersResponseSchema>;
const appliedFilters = useFormValues<FilterSchema>();

const show = computed(() => !!filters.value?.prices);
const field = useField<{ from: string; to: string } | undefined>('price');
const drawerToggle = ref(false);
const toggleDropdown = () => {
	drawerToggle.value = !drawerToggle.value;
};

const filterCount = computed(() => {
	if (appliedFilters.value.price) {
		if (
			filters.value.prices?.max_price !== appliedFilters.value.price.to ||
			filters.value.prices?.min_price !== appliedFilters.value.price.from
		) {
			return 1;
		}
	}
	return 0;
});

const resetField = () => {
	toggleDropdown();
	field.setValue(undefined);
};
</script>
<template>
	<div v-if="show">
		<Drawer v-if="isMobileView()">
			<DrawerTrigger :as-child="true">
				<Button
					size="xs"
					variant="secondary"
					class="price_filter_drawer_trigger"
					:class="filterCount ? activeFilterStyle : ''"
				>
					Price
					<PhCaretDown :weight="'bold'" />
				</Button>
			</DrawerTrigger>
			<DrawerContent
				side="bottom"
				class="price_filter_drawer_content max-h-[75%] pb-[150px]"
			>
				<DrawerHeader>
					<DrawerTitle>Price</DrawerTitle>
				</DrawerHeader>

				<h1
					v-if="filterCount > 0"
					class="font-semibold text-gray-800 text-md p-7xl"
				>
					Selected Filters
				</h1>
				<AppliedFilters :filter-applied="'prices'" class="p-7xl pt-0" />

				<DrawerDescription class="p-3xl">
					<PriceFilterForm />
				</DrawerDescription>
				<DrawerFooter
					class="absolute bottom-0 w-full bg-white left-none right-none"
				>
					<DrawerTrigger :as-child="true">
						<Button
							size="lg"
							variant="primary"
							class="grow"
							type="submit"
							form="filterForm"
						>
							Apply
						</Button>
					</DrawerTrigger>
					<DrawerTrigger :as-child="true">
						<Button
							size="lg"
							variant="secondary"
							form="filterForm"
							type="submit"
							@click="resetField"
						>
							Reset
						</Button>
					</DrawerTrigger>
				</DrawerFooter>
			</DrawerContent>
		</Drawer>

		<DropdownMenu v-else v-model:open="drawerToggle">
			<DropdownMenuTrigger as-child>
				<Button
					size="md"
					variant="secondary"
					class="price_filter_drawer_trigger"
					:class="filterCount ? activeFilterStyle : ''"
				>
					Price
					<PhCaretDown :weight="'bold'" />
				</Button>
			</DropdownMenuTrigger>
			<DropdownMenuContent class="w-[432px] bg-white">
				<DropdownMenuLabel
					class="text-xl font-semibold text-center px-3xl py-3xl font-gray-800"
				>
					Price
				</DropdownMenuLabel>

				<DropdownMenuSeparator />
				<h1
					v-if="filterCount > 0"
					class="font-semibold text-gray-800 text-md p-7xl"
				>
					Selected Filters
				</h1>
				<AppliedFilters :filter-applied="'price'" class="p-7xl pt-0" />
				<DropdownMenuSeparator v-if="filterCount > 0" />
				<PriceFilterForm class="p-7xl" />

				<DropdownMenuSeparator v-if="filterCount > 0" />

				<div class="px-3xl py-7xl flex items-center gap-md">
					<Button
						size="lg"
						variant="primary"
						class="grow bg-gray-800 w-full"
						type="submit"
						form="filterForm"
						@click="toggleDropdown()"
					>
						Apply
					</Button>
					<Button
						size="lg"
						variant="secondary"
						class="w-full discount_filter_reset"
						type="submit"
						form="filterForm"
						@click="resetField"
					>
						Reset
					</Button>
				</div>
			</DropdownMenuContent>
		</DropdownMenu>
	</div>
</template>
