<script setup lang="ts">
import type { FiltersResponseSchema } from '@laam/cms-shared';
import { ToggleButton, FormField, Input } from '@laam/ui/base';
import { PhMagnifyingGlass } from '@phosphor-icons/vue';
import { computed, inject, type Ref } from 'vue';
import { ref } from 'vue';

const emit = defineEmits<{
	(
		e: 'update-filter',
		value: {
			filterType: string;
			filterValue: string;
			source: string;
		},
	): void;
}>();

const searchVal = ref('');
const filters = inject('filters') as Ref<FiltersResponseSchema>;
const categories = computed(() => {
	return [
		'shirt-fabrics',
		'dupatta-fabric',
		'fabric',
		'fabric painting',
		'poly fabric',
	];
});
const fabricTree = computed(() => {
	if (searchVal.value != '') {
		const result: {
			'shirt-fabrics': string[];
			'dupatta-fabric': string[];
			fabric: string[];
			'fabric painting': string[];
			'poly fabric': string[];
		} = {
			'shirt-fabrics': [],
			'dupatta-fabric': [],
			fabric: [],
			'fabric painting': [],
			'poly fabric': [],
		};
		categories.value.forEach((category: string) => {
			if (!filters.value.fabrics) {
				throw new Error('fabric filters undefined');
			}
			result[category as keyof typeof result] =
				filters.value.fabrics[
					category as keyof typeof filters.value.fabrics
				]?.filter((item: string) =>
					item.toLowerCase().includes(searchVal.value.toLowerCase()),
				) ?? [];
		});
		return result;
	}
	return filters.value.fabrics!;
});
const logEvent = (fabric: string) => {
	emit('update-filter', {
		filterType: 'fabrics',
		filterValue: fabric,
		source: window.location.href,
	});
};
</script>
<template>
	<div class="fabric_filter_form gap-3xl flex flex-col">
		<Input
			v-model:model-value="searchVal"
			:auto-focus="false"
			placeholder="Search"
		>
			<template #leftIcon>
				<PhMagnifyingGlass size="16" />
			</template>
		</Input>
		<template v-for="category in categories" :key="category">
			<div
				v-if="fabricTree[category as keyof typeof fabricTree]!.length > 0"
				class="gap-xl flex flex-col"
			>
				<h2
					v-if="!!category && categories.length > 1"
					class="text-md font-semibold capitalize"
				>
					{{ category.replace('-', ' ') }}
				</h2>
				<div class="gap-xl flex flex-wrap">
					<FormField
						v-for="fabric in fabricTree[category as keyof typeof fabricTree]!"
						v-slot="{ handleChange, value }"
						:key="fabric"
						type="checkbox"
						name="attrs"
						:value="`${category}.${fabric}`"
						:unchecked-value="false"
					>
						<ToggleButton
							:checked="value?.includes(`${category}.${fabric}`)"
							size="sm"
							class="font-medium capitalize"
							@update:checked="
								(e) => {
									handleChange(e);
									logEvent(`${category}.${fabric}`);
								}
							"
						>
							{{ fabric }}
						</ToggleButton>
					</FormField>
				</div>
			</div>

			<div
				v-if="
					category !== categories[categories.length - 1] &&
					fabricTree[category as keyof typeof fabricTree]!.length > 0
				"
				class="h-[1px] bg-gray-200 w-full"
			/>
		</template>
	</div>
</template>
