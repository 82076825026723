<script setup lang="ts">
import type { FiltersResponseSchema } from '@laam/cms-shared';
import { FormField, ToggleButton, Button } from '@laam/ui/base';
import { computed, inject, ref, type Ref } from 'vue';

const emit = defineEmits<{
	(
		e: 'update-filter',
		value: {
			filterType: string;
			filterValue: string;
			source: string;
		},
	): void;
}>();
const filters = inject('filters') as Ref<FiltersResponseSchema>;

function getDiscountLabel(discount: string) {
	if (discount === 'sale') {
		return discount;
	} else {
		return `${discount.split('_').join(' ')}% off`;
	}
}

const discounts = computed(() => {
	return (
		filters.value.discounts?.map((discount) => ({
			label: getDiscountLabel(discount),
			value: discount,
		})) || []
	);
});

const PAGE_LIMIT = 10;

const currentPage = ref(1);

const totalPages = computed(() =>
	Math.ceil(discounts.value.length / PAGE_LIMIT),
);

const slicedDiscountsList = computed(() => {
	return discounts.value.slice(0, currentPage.value * PAGE_LIMIT);
});

const handlePageChange = () => {
	currentPage.value++;
};

const logEvent = (discount: string) => {
	emit('update-filter', {
		filterType: 'discount',
		filterValue: discount,
		source: window.location.href,
	});
};
</script>
<template>
	<div class="flex flex-wrap discount_filter_form gap-xl">
		<FormField
			v-for="discount in slicedDiscountsList"
			v-slot="{ handleChange, value }"
			:key="discount.value"
			type="checkbox"
			name="discount"
			:value="discount.value"
			:unchecked-value="false"
		>
			<ToggleButton
				:checked="!!value.includes(discount.value)"
				size="sm"
				class="font-medium capitalize"
				@update:checked="
					(e) => {
						handleChange(e);
						logEvent(discount.value);
					}
				"
			>
				{{ discount.label }}
			</ToggleButton>
		</FormField>
		<Button
			v-if="currentPage < totalPages"
			class="font-medium"
			variant="secondary"
			@click="handlePageChange"
		>
			...
		</Button>
	</div>
</template>
