<script setup lang="ts">
import { type HTMLAttributes, computed } from 'vue';
import type { SliderRootEmits, SliderRootProps } from 'radix-vue';
import {
	SliderRange,
	SliderRoot,
	SliderThumb,
	SliderTrack,
	useForwardPropsEmits,
} from 'radix-vue';
import { cn } from '@laam/lib/utils';

const props = defineProps<
	SliderRootProps & { class?: HTMLAttributes['class'] }
>();
const emits = defineEmits<SliderRootEmits>();

const delegatedProps = computed(() => {
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const { class: _, ...delegated } = props;

	return delegated;
});
const sliderThumbClassList =
	'block h-7xl w-7xl rounded-full border-[1.5px] border-gray-800 bg-white ring-offset-white transition-colors disabled:pointer-events-none disabled:opacity-50';

const forwarded = useForwardPropsEmits(delegatedProps, emits);
</script>

<template>
	<SliderRoot
		:class="
			cn(
				'relative flex w-full touch-none select-none items-center',
				props.class,
			)
		"
		v-bind="forwarded"
	>
		<SliderTrack
			class="relative h-md w-full grow overflow-hidden rounded-full bg-gray-200"
		>
			<SliderRange class="absolute h-full bg-gray-900" />
		</SliderTrack>
		<SliderThumb :class="sliderThumbClassList" />
		<SliderThumb :class="sliderThumbClassList" />
	</SliderRoot>
</template>
