<script setup lang="ts">
import {
	PriceFilterForm,
	SizeFilterForm,
	DiscountFilterForm,
	DeliveryFilterForm,
	StyleFilterForm,
	BrandFilterForm,
	FabricFilterForm,
	ColorFilterForm,
	SeasonFilterForm,
	NoOfPiecesFilterForm,
	WorkTechniqueFilterForm,
	AppliedFilters,
	SortFilterForm,
} from '@laam/ui';
import { hasCategories } from '~/data/filters';
import type { FiltersResponseSchema } from '@laam/cms-shared';

const filters = inject('filters') as Ref<FiltersResponseSchema>;
const newFiltersEnabled = inject('newFiltersEnabled') as Ref<Boolean>;
const plpNewUi = inject('plpNewUi') as Ref<boolean>;
</script>
<template>
	<div class="flex flex-col all_filter_form gap-3xl">
		<h1 class="font-semibold text-gray-800 text-md">Selected Filters</h1>
		<AppliedFilters />
		<div class="h-[1px] bg-gray-200" />
		<template v-if="plpNewUi">
			<h1 class="font-semibold text-gray-800 text-md">Sort by</h1>
			<SortFilterForm />
		</template>

		<h1 class="font-semibold text-gray-800 text-md">Price</h1>
		<PriceFilterForm />
		<div class="h-[1px] bg-gray-200" />

		<template v-if="hasCategories(filters?.size)">
			<h1 class="font-semibold text-gray-800 text-md">Size</h1>
			<SizeFilterForm />
			<div class="h-[1px] bg-gray-200" />
		</template>

		<template v-if="!!filters.discounts?.length">
			<h1 class="font-semibold text-gray-800 text-md">Discounts</h1>
			<DiscountFilterForm />
			<div class="h-[1px] bg-gray-200" />
		</template>

		<template v-if="!!filters.delivery_days?.length">
			<h1 class="font-semibold text-gray-800 text-md">Delivery</h1>
			<DeliveryFilterForm />
			<div class="h-[1px] bg-gray-200" />
		</template>

		<template v-if="hasCategories(filters.nodes)">
			<h1 class="font-semibold text-gray-800 text-md">Styles</h1>
			<StyleFilterForm />
			<div class="h-[1px] bg-gray-200" />
		</template>

		<template v-if="!!filters.brands?.length">
			<h1 class="font-semibold text-gray-800 text-md">Brands</h1>
			<BrandFilterForm />
			<div class="h-[1px] bg-gray-200" />
		</template>
		<template v-if="newFiltersEnabled">
			<template v-if="hasCategories(filters.fabrics)">
				<h1 class="font-semibold text-gray-800 text-md">Fabric</h1>
				<FabricFilterForm />
				<div class="h-[1px] bg-gray-200" />
			</template>
			<template v-if="!!filters['attrs.color-type']?.length">
				<h1 class="font-semibold text-gray-800 text-md">Color</h1>
				<ColorFilterForm />
				<div class="h-[1px] bg-gray-200" />
			</template>
			<template v-if="!!filters['attrs.season']?.length">
				<h1 class="font-semibold text-gray-800 text-md">Season</h1>
				<SeasonFilterForm />
				<div class="h-[1px] bg-gray-200" />
			</template>
			<template v-if="!!filters['attrs.number-of-pieces']?.length">
				<h1 class="font-semibold text-gray-800 text-md">No of Pieces</h1>
				<NoOfPiecesFilterForm />
				<div class="h-[1px] bg-gray-200" />
			</template>
			<template v-if="!!filters['attrs.work-technique']?.length">
				<h1 class="font-semibold text-gray-800 text-md">Work Technique</h1>
				<WorkTechniqueFilterForm />
				<div class="h-[1px] bg-gray-200" />
			</template>
		</template>
	</div>
</template>
