import {
	NON_ARRAY_FILTERS,
	type ARRAY_FILTERS,
	type FilterSchema,
} from '@laam/cms-shared';

export const getFiltersFromUrl = (url: string): FilterSchema => {
	const searchParams = new URL(url).searchParams;
	const filters: FilterSchema = {};
	// get all keys from the search params
	const keys = Array.from(searchParams.keys());
	const arrayKeys = keys.filter((k) => !NON_ARRAY_FILTERS.includes(k));
	// handle all array filters
	for (const key of arrayKeys) {
		const value = searchParams.get(key)!.split(',');
		filters[key as ARRAY_FILTERS] = value;
	}

	// handle price filters
	if (searchParams.has('price.from') && searchParams.has('price.to')) {
		const from = searchParams.get('price.from')!;
		const to = searchParams.get('price.to')!;
		filters.price = { from: parseFloat(from), to: parseFloat(to) };
	}
	// handle sort filter
	if (searchParams.has('sort_by')) {
		filters.sort_by = searchParams.get('sort_by')!;
	}
	return filters;
};

export const applyFilters = (
	currentLocation: string,
	filters: FilterSchema,
) => {
	const url = new URL(currentLocation);
	const searchParams = new URLSearchParams();

	for (const key of Object.keys(filters)) {
		const typedKey = key as keyof FilterSchema;
		if (!filters[typedKey]) continue;
		const value = filters[typedKey];
		if (Array.isArray(value)) {
			searchParams.set(key, value.join(','));
		} else {
			if (typeof value === 'object') {
				for (const k of Object.keys(value) as (keyof typeof value)[]) {
					searchParams.set(`${key}.${k}`, value[k].toString());
				}
			} else {
				searchParams.set(key, value!);
			}
		}
	}

	return `${url.pathname}?${searchParams.toString()}`;
};
