<script setup lang="ts">
import { computed, inject, type Ref } from 'vue';
import { useFormValues, useSetFormValues } from 'vee-validate';
import type { FilterSchema, FiltersResponseSchema } from '@laam/cms-shared';
import { RemoveFilterIcon } from '../../icons/index';

interface AppliedFiltersProps {
	filterApplied?: string;
	filterValues?: string | string[];
}
const props = defineProps<AppliedFiltersProps>();
const appliedFilters = useFormValues<FilterSchema>();

const setFormValues = useSetFormValues();

const filters = inject('filters') as Ref<FiltersResponseSchema>;

interface FilterItem {
	label: string | undefined;
	originalVal: unknown;
	key: keyof FilterSchema;
}
const individualFilters = computed(() => {
	let updatedFilters: FilterItem[] = [];
	const keys = Object.keys(appliedFilters.value || {}).filter(
		(key) => key !== 'search',
	);

	for (const key of keys) {
		const val = appliedFilters.value[key as keyof FilterSchema];

		if (key == 'sort') {
			continue;
		} else if (key == 'price' && val) {
			const price = val as FilterSchema['price'];
			if (
				price!.from !== filters.value.prices?.min_price.toString() ||
				price!.to !== filters.value.prices?.max_price.toString()
			)
				updatedFilters.push({
					label: `${price!.from} PKR - ${price!.to} PKR`,
					originalVal: val,
					key,
				});
		} else if (key === 'node_id') {
			const nodeIds = val as FilterSchema['node_id'];
			nodeIds?.forEach((nodeId: string) => {
				const nodeTitle = findNodeTitleById(filters?.value?.nodes, nodeId);
				if (nodeTitle) {
					updatedFilters.push({
						label: `Style: ${nodeTitle}`,
						originalVal: nodeId,
						key: 'node_id',
					});
				}
			});
		} else {
			if (val && Array.isArray(val) && val.length > 0 && key !== 'node_id') {
				val.forEach((item) => {
					const splitItem = item.includes('.') ? item.split('.')[1] : item;
					let label;
					if (key.includes('attrs')) {
						const splitLabels = item.split('.');
						label = `${splitLabels[0]?.replace('-', ' ')}: ${splitLabels[1]}`;
					} else if (
						item.includes('kids') ||
						item.includes('women') ||
						item.includes('men')
					) {
						label = `Size: ${splitItem}`;
					} else {
						label = `${key.replace('_', ' ')}: ${splitItem}`;
					}
					updatedFilters.push({
						label,
						originalVal: item,
						key: key as keyof FilterSchema,
					});
				});
			}
		}
	}
	if (props.filterApplied && updatedFilters.length > 0) {
		updatedFilters = updatedFilters.filter((filter) => {
			if (filter.key === 'attrs') {
				const filterValue = filter.originalVal as FilterSchema['attrs'];
				return !!filterValue?.includes(props.filterApplied!);
			} else {
				return !!filter.key.includes(props.filterApplied!);
			}
		});
	}

	return updatedFilters;
});

const findNodeTitleById = (
	nodes: FiltersResponseSchema['nodes'],
	nodeId: string | undefined,
) => {
	if (nodes) {
		for (const node of nodes) {
			if (node.children && node.children.length > 0) {
				for (const children of node.children) {
					if (children.id.toString() === nodeId) {
						const title = children.title;
						return title;
					}
				}
			}
		}
		return null;
	}
	return null;
};

const removeFilter = (filter: FilterItem) => {
	const val = appliedFilters.value[filter.key];
	if (Array.isArray(val) && val.length > 0) {
		setFormValues({
			...appliedFilters.value,
			[filter.key]: val.filter((item: any) => item !== filter.originalVal),
		});
	} else {
		setFormValues({
			...appliedFilters.value,
			[filter.key]: undefined,
		});
	}
};
</script>
<template>
	<div class="flex flex-wrap applied_filters_form gap-xl">
		<div
			v-for="filter in individualFilters"
			:key="filter.label"
			class="h-[28px] bg-gray-50 rounded-2xl py-xs px-lg gap-xs flex justify-center items-center border-gray-200 border-[1px] applied-filters"
		>
			<p class="text-sm font-normal text-gray-700 capitalize">
				{{ filter.label }}
			</p>

			<RemoveFilterIcon class="cursor-pointer" @click="removeFilter(filter)" />
		</div>
	</div>
</template>
