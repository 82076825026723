<script setup lang="ts">
import type { FiltersResponseSchema } from '@laam/cms-shared';
import { Slider, Input } from '@laam/ui/base';
import { useField } from 'vee-validate';
import { computed, inject, onMounted, ref, watch, type Ref } from 'vue';

const emit = defineEmits<{
	(
		e: 'update-filter',
		value: {
			filterType: string;
			filterValue: {
				toPrice: number;
				fromPrice: number;
			};
			source: string;
		},
	): void;
}>();

const filters = inject('filters') as Ref<FiltersResponseSchema>;
const fieldPriceMin = useField<number>('price.from');
const fieldPriceMax = useField<number>('price.to');

const MIN_PRICE = filters.value.prices!.min_price;
const MAX_PRICE = filters.value.prices!.max_price;

onMounted(() => {
	if (!fieldPriceMin.value.value || !fieldPriceMax.value.value) {
		fieldPriceMin.setValue(MIN_PRICE);
		fieldPriceMax.setValue(MAX_PRICE);
	}
});

const isPriceFilterInitialized = computed(
	() => fieldPriceMin.value.value && fieldPriceMax.value.value,
);

const fromPriceValue = ref(fieldPriceMin.value.value ?? MIN_PRICE);
const toPriceValue = ref(fieldPriceMax.value.value ?? MAX_PRICE);

watch(fromPriceValue, (value) => {
	if (value) {
		fieldPriceMin.setValue(value);
	}
});
watch(toPriceValue, (value) => {
	if (value) {
		fieldPriceMax.setValue(value);
	}
});

const handleChange = (value: number[] | undefined) => {
	if (value && value.length === 2) {
		fieldPriceMin.setValue(value[0]!);
		fieldPriceMax.setValue(value[1]!);
		fromPriceValue.value = value[0]!;
		toPriceValue.value = value[1]!;
		setTimeout(() => {
			emit('update-filter', {
				filterType: 'price',
				filterValue: {
					fromPrice: fromPriceValue.value,
					toPrice: toPriceValue.value,
				},
				source: window.location.href,
			});
		}, 1000);
	}
};

const handleFromPriceChange = (value: string | number) => {
	fromPriceValue.value = parseFloat(value.toString());
};
const handleToPriceChange = (value: string | number) => {
	toPriceValue.value = parseFloat(value.toString());
};
</script>
<template>
	<div class="flex flex-col price_filter_form gap-3xl">
		<div class="flex w-full gap-3xl">
			<div class="flex flex-col overflow-hidden gap-sm grow">
				<label for="price-from" class="text-sm font-medium">From</label>
				<Input
					v-model:model-value="fromPriceValue"
					type="number"
					placeholder="From"
					name="price-from"
					@update:model-value="handleFromPriceChange"
				/>
			</div>

			<div class="flex flex-col overflow-hidden gap-sm grow">
				<label for="price-to" class="text-sm font-medium">To</label>
				<Input
					v-model:model-value="toPriceValue"
					type="number"
					placeholder="To"
					name="price-to"
					@update:model-value="handleToPriceChange"
				/>
			</div>
		</div>
		<Slider
			:min="MIN_PRICE"
			:max="MAX_PRICE"
			:step="500"
			:model-value="
				isPriceFilterInitialized
					? [fieldPriceMin.value.value, fieldPriceMax.value.value]
					: [MIN_PRICE, MAX_PRICE]
			"
			@update:model-value="handleChange"
		/>
	</div>
</template>
