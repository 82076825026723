<script setup lang="ts">
import type { FiltersResponseSchema } from '@laam/cms-shared';
import { FormField, ToggleButton, Input } from '@laam/ui/base';
import { PhMagnifyingGlass } from '@phosphor-icons/vue';
import { computed, inject, ref, type Ref } from 'vue';

const emit = defineEmits<{
	(
		e: 'update-filter',
		value: {
			filterType: string;
			filterValue: string;
			source: string;
		},
	): void;
}>();

const filters = inject('filters') as Ref<FiltersResponseSchema>;
const searchVal = ref('');
const workTechnique = computed(() => {
	let techniques = filters.value['attrs.work-technique'];
	if (searchVal.value != '') {
		techniques = techniques?.filter((technique: string) =>
			technique.toLowerCase().includes(searchVal.value.toLowerCase()),
		);
	}
	return (
		techniques?.map((technique) => ({
			label: technique,
			value: technique,
		})) || []
	);
});
const logEvent = (workTechnique: string) => {
	emit('update-filter', {
		filterType: 'work-technique',
		filterValue: workTechnique,
		source: window.location.href,
	});
};
</script>
<template>
	<div class="flex flex-wrap work_technique_filter_form gap-xl">
		<Input
			v-model:model-value="searchVal"
			:auto-focus="false"
			placeholder="Search"
		>
			<template #leftIcon>
				<PhMagnifyingGlass size="16" />
			</template>
		</Input>
		<FormField
			v-for="technique in workTechnique"
			v-slot="{ handleChange, value }"
			:key="technique.value"
			type="checkbox"
			name="attrs"
			:value="`work-technique.${technique.value}`"
			:unchecked-value="false"
		>
			<ToggleButton
				:checked="!!value.includes(`work-technique.${technique.value}`)"
				size="sm"
				class="font-medium capitalize"
				@update:checked="
					(e) => {
						handleChange(e);
						logEvent(`work-technique.${technique.value}`);
					}
				"
			>
				{{ technique.label }}
			</ToggleButton>
		</FormField>
	</div>
</template>
