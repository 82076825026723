<script lang="ts" setup>
import type { DrawerDescriptionProps } from 'vaul-vue';
import { DrawerDescription } from 'vaul-vue';
import { type HtmlHTMLAttributes, computed } from 'vue';
import { cn } from '@laam/lib/utils';

const props = defineProps<
	DrawerDescriptionProps & { class?: HtmlHTMLAttributes['class'] }
>();

const delegatedProps = computed(() => {
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const { class: _, ...delegated } = props;

	return delegated;
});
</script>

<template>
	<DrawerDescription
		v-bind="delegatedProps"
		:class="cn('text-sm text-neutral-500', props.class)"
	>
		<slot />
	</DrawerDescription>
</template>
